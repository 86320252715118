import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import { translationPropType } from '@eventbrite/i18n';
import { FormField } from '@eventbrite/eds-form-field';

import * as constants from './constants';
import { mergeEventHandlers } from './reduxFormUtils';

export default class ValidatedFormField extends PureComponent {
    static propTypes = {
        ...omit(FormField.propTypes, ['label', 'children']),
        // input state provided by redux-form
        input: PropTypes.shape({
            checked: PropTypes.bool,
            name: PropTypes.string,
            onBlur: PropTypes.func,
            onChange: PropTypes.func,
            onDragStart: PropTypes.func,
            onDrop: PropTypes.func,
            onFocus: PropTypes.func,
            value: PropTypes.any,
        }),
        // meta information provided by redux-form
        meta: PropTypes.shape({
            active: PropTypes.bool,
            asyncValidating: PropTypes.bool,
            dirty: PropTypes.bool,
            error: translationPropType,
            invalid: PropTypes.bool,
            pristine: PropTypes.bool,
            touched: PropTypes.bool,
            valid: PropTypes.bool,
            visited: PropTypes.bool,
        }),
        // additional parameters passed through ValidationFormField
        shouldDisplayError: PropTypes.func,
        submitFailed: PropTypes.bool,
    };

    static contextTypes = {
        _reduxForm: PropTypes.object,
    };

    UNSAFE_componentWillMount() {
        this._callOnChangeIfNeeded(this.props);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this._callOnChangeIfNeeded(props);
    }

    /**
     * Returns whether the current value in the form's state differs from the
     * value passed to this component.
     *
     * @param value
     * @returns {boolean}
     * @private
     */
    _valueDiffersFromState(value) {
        const path = this.props.input.name.split('.');

        const stateValue = reduce(
            path,
            (memo = {}, pathKey) => memo[pathKey],
            this.context._reduxForm.getValues(),
        );

        return stateValue !== value;
    }

    /**
     * This method raises a change event if the value passed to the input differs
     * from the value in the redux store. Ideally, this shouldn't happen, but due
     * to some eccentricities with redux-form it unfortunately seems to be
     * unavoidable at times.
     * @private
     */
    _callOnChangeIfNeeded({ input: { value, onChange }, children }) {
        if (
            React.Children.count(children) > 0 &&
            children.props.value !== undefined &&
            children.props.value !== value
        ) {
            onChange(children.props.value);
        } else if (value && this._valueDiffersFromState(value)) {
            onChange(value);
        }
    }

    render() {
        const {
            shouldDisplayError,
            children,
            label,
            htmlFor,
            required,
            hideLabel,
            bottomSpacing,
            annotationType,
            annotationNote,
            annotationDecoration,
            annotationCustomClassName,
            submitFailed,
            input,
            meta: { error, dirty, active, touched },
            wrappedComponent: WrappedComponent,
            wrappedComponentProps = {},
            ...additionalProps
        } = this.props;
        let newAnnotationType = annotationType;
        let newAnnotationNote = annotationNote;

        let componentProps;

        if (React.Children.count(children) > 0) {
            componentProps = children;
        } else {
            componentProps = { props: wrappedComponentProps };
        }

        const inputProps = {
            ...componentProps.props,
            ...additionalProps,
            ...mergeEventHandlers(componentProps, input),
            value: input.value,
            required,
            hasError: false,
        };

        const validationProps = {
            error,
            submitFailed,
            dirty,
            active,
            touched,
            value: input.value,
        };

        if (error && shouldDisplayError(validationProps)) {
            newAnnotationNote = error;
            newAnnotationType = constants.ANNOTATION_TYPE_ERROR;
            inputProps.hasError = true;
        }

        const formFieldProps = {
            label,
            htmlFor,
            required,
            hideLabel,
            bottomSpacing,
            annotationType: newAnnotationType,
            annotationNote: newAnnotationNote,
            annotationDecoration,
            annotationCustomClassName,
        };

        if (WrappedComponent) {
            return (
                <FormField {...formFieldProps}>
                    <WrappedComponent {...inputProps} />
                </FormField>
            );
        } else if (React.Children.count(children) > 0) {
            return (
                <FormField {...formFieldProps}>
                    {React.cloneElement(children, inputProps)}
                </FormField>
            );
        }
    }
}
