import React, { PureComponent } from 'react';

// original author: gago
import PropTypes from 'prop-types';

import { gettext } from '@eventbrite/i18n';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import getPaginationInfo from './lib';

import { Button } from '@eventbrite/eds-button';
import { Icon } from '@eventbrite/eds-icon';
import { ChevronLeftChunky } from '@eventbrite/eds-iconography';
import { ChevronRightChunky } from '@eventbrite/eds-iconography';
import { SelectField } from '@eventbrite/eds-input-field';
import { getSelectPropValues } from '@eventbrite/eds-select';

import * as constants from './constants';

import './pagination.scss';

const Ellipsis = ({ show }) => {
    let component = null;

    if (show) {
        component = (
            <li className="eds-l-mar-vert-2">
                <span>&hellip;</span>
            </li>
        );
    }
    return component;
};

const PaginationBound = ({ show, bound = 1, onSelected }) => {
    let component = null;

    if (show) {
        component = (
            <PaginationPage
                isSelected={false}
                isDisabled={false}
                data-spec={`page-bound-${bound}`}
                onSelected={onSelected.bind(null, bound)}
            >
                <span className="eds-pagination__navigation-page__item eds-l-mar-vert-2 eds-l-mar-hor-2">
                    {bound}
                </span>
            </PaginationPage>
        );
    }
    return component;
};

const PaginationSummary = ({ summary }) => {
    let component = null;

    if (summary) {
        component = (
            <span data-spec="summary" className="eds-text-bs eds-l-mar-2">
                {summary}
            </span>
        );
    }

    return component;
};

const PaginationPages = ({ items, onSelected, size, pageCount }) => {
    if (
        (size === constants.PAGE_SIZE_MIN ||
            size === constants.PAGE_SIZE_CONTINUOUS) &&
        items[0]
    ) {
        const pageNum = items[0].number;

        // Continuous pagination varies slightly from Minimal as it only shows left/right
        // arrows and the current page. ex: `< 5 >`
        // Minimal shows left/right arrows and current page of total pages. ex: `< 5 of 9 >`
        if (size === constants.PAGE_SIZE_CONTINUOUS) {
            return (
                <li
                    className="eds-pagination__navigation-continuous eds-l-mar-hor-3"
                    data-spec="eds-pagination__navigation-continuous"
                >
                    <span
                        className="eds-text-color--control eds-text-weight--heavy"
                        data-testid="pagination-string"
                    >
                        {pageNum}
                    </span>
                </li>
            );
        }

        const gettextWithMarkup = {
            __html: gettext(
                '<span data-testid="pagination-string" class="eds-text-color--control eds-text-weight--heavy">%(pageNum)s</span> of %(pageCount)s',
                { pageNum, pageCount },
                true, // Sanitize the output of the HTML since it is going into dangerouslySetInnerHTML
            ).toString(),
        };

        return (
            <li
                className="eds-pagination__navigation-minimal eds-l-mar-hor-3"
                data-spec="pagination-parent"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={gettextWithMarkup}
            />
        );
    }

    const pages = items.map(({ number, isSelected, isDisabled }) => (
        <PaginationPage
            key={number}
            isSelected={isSelected}
            isDisabled={isDisabled}
            data-spec={`page-${number}`}
            onSelected={onSelected.bind(null, number)}
        >
            <span className="eds-pagination__navigation-page__item eds-l-mar-vert-2 eds-l-mar-hor-2">
                {number}
            </span>
        </PaginationPage>
    ));

    return (
        <li>
            <ul className="eds-pagination__navigation-pages">{pages}</ul>
        </li>
    );
};

const PaginationPage = ({
    isSelected,
    isDisabled,
    onSelected,
    children,
    'data-spec': dataSpec,
    show = true,
    isLoading,
    ...extraProps
}) => {
    let component = null;

    const className = classNames(
        'eds-pagination__navigation-page',
        'eds-fx--fade-in',
        {
            'eds-pagination__navigation-page--is-selected': isSelected,
            'eds-pagination__navigation-page--is-disabled': isDisabled,
        },
    );

    if (show) {
        component = (
            <li className={className} data-spec={`${dataSpec}-wrapper`}>
                <Button
                    style="none"
                    data-spec={dataSpec}
                    disabled={isLoading || isDisabled}
                    onClick={onSelected}
                    tabIndex={0}
                    {...extraProps}
                >
                    {children}
                </Button>
            </li>
        );
    }

    return component;
};

const ResultsPerPageSelect = ({
    resultsPerPage,
    resultsPerPageOptions,
    onChange,
    hasOnChange,
}) => {
    if (!resultsPerPageOptions || !hasOnChange) {
        return null;
    }

    const values = getSelectPropValues(resultsPerPageOptions);
    const value = String(resultsPerPage);

    return (
        <div
            className="eds-pagination__results-per-page-select"
            data-spec="pagination__results-per-page-select"
        >
            <SelectField
                values={values}
                value={value}
                label={gettext('Show')}
                onChange={onChange}
            />
        </div>
    );
};

const ResultsMessage = ({
    showResultsMessage,
    totalNumResults,
    currentResultMin,
    currentResultMax,
}) => {
    if (!totalNumResults || !showResultsMessage) {
        return null;
    }

    const resultsMsg = gettext(
        'Results %(currentResultMin)s-%(currentResultMax)s of %(totalNumResults)s',
        { currentResultMin, currentResultMax, totalNumResults },
    );

    return (
        <div
            className="eds-pagination__results-msg"
            data-spec="pagination__results-msg"
        >
            {resultsMsg}
        </div>
    );
};

export default class Pagination extends PureComponent {
    static propTypes = {
        /**
         * Callback function to be invoked on page size change.
         * Passing this prop also determines whether or not to show the resultsPerPageOptions dropdown.
         */
        onResultsPerPageChange: PropTypes.func,
        /**
         * Callback function to be invoked on select
         */
        onSelected: PropTypes.func.isRequired,
        pageCount: PropTypes.number,
        /**
         * Page number (will default/override internal state).
         * a.k.a. current page number
         */
        pageNumber: PropTypes.number,
        /**
         * The currently selected page size (will default/override internal state)
         */
        resultsPerPage: PropTypes.number,
        /**
         * Array of page sizes to show in dropdown ex: [20, 30, 40, 50]
         */
        resultsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
        /**
         * Display a range message. ex: "Results 1-20 of 620".
         * Results message will only display when totalNumResults is also set.
         */
        showResultsMessage: PropTypes.bool,
        /**
         * How many page options to show at a given time.
         * Small currently maps to medium for backwards compatibility.
         * Minimal is equivalent to the spec for small.
         * (continuous, minimal, small, medium, large)
         */
        size: PropTypes.oneOf(constants.AVAILABLE_PAGE_SIZES),
        /**
         * Hide pagination if there is only a single page
         */
        showOnSinglePage: PropTypes.bool,
        /**
         * Changes the style (colour) of the active pagination number.
         * Available styles: organizer ($theme-control), consumer ($theme-primary-brand)
         */
        style: PropTypes.oneOf(constants.STYLES),
        /**
         * Description that appears below the page buttons
         */
        summary: PropTypes.node,
        /**
         * Total number of results.
         * Either totalNumResults or pageCount are required props.
         * Since the pageCount prop is on path to deprecation, using totalNumResults is recommended.
         */
        totalNumResults: constants.NUM_RESULTS_PROP_TYPE,
        /**
         * isLoading prop to be passed to the pagination page buttons
         * This prop is used to disable the pagination buttons when the component is in a loading state
         */
        isLoading: PropTypes.bool,
    };

    static defaultProps = {
        resultsPerPageOptions: constants.DEFAULT_RESULTS_PER_PAGE_OPTIONS,
        resultsPerPage: constants.DEFAULT_RESULTS_PER_PAGE,
        pageNumber: 1,
        size: constants.DEFAULT_PAGE_SIZE,
        showOnSinglePage: true,
        style: constants.STYLE_ORGANIZER,
        showResultsMessage: false,
        isLoading: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            currentPage: props.pageNumber,
            currentResultsPerPage: props.resultsPerPage,
        };
    }

    UNSAFE_componentWillReceiveProps({ pageNumber, resultsPerPage }) {
        const updatedProps = {};

        if (pageNumber !== this.state.currentPage) {
            updatedProps.currentPage = pageNumber;
        }

        if (resultsPerPage !== this.state.currentResultsPerPage) {
            updatedProps.currentResultsPerPage = resultsPerPage;
        }

        if (!isEmpty(updatedProps)) {
            this.setState(updatedProps);
        }
    }

    _handleSelected(pageNumber) {
        const { pageCount, onSelected } = this.props;
        const { currentPage } = this.state;

        if (
            pageNumber < 1 ||
            pageNumber > pageCount ||
            pageNumber === currentPage
        ) {
            return;
        }

        this.setState({ currentPage: pageNumber });

        onSelected(pageNumber);
    }

    _handleResultsPerPageChange(value) {
        const { onResultsPerPageChange } = this.props;
        const intValue = parseInt(value, 10);

        this.setState({
            currentResultsPerPage: intValue,
            currentPage: 1,
        });

        if (onResultsPerPageChange) {
            onResultsPerPageChange(intValue);
        }
    }

    render() {
        const { currentPage, currentResultsPerPage } = this.state;
        const {
            resultsPerPageOptions,
            totalNumResults,
            showResultsMessage,
            onResultsPerPageChange,
            pageCount,
            size,
            summary,
            showOnSinglePage,
            style,
            isLoading,
        } = this.props;
        const pageInfo = getPaginationInfo({
            pageNumber: currentPage,
            pageCount,
            totalNumResults,
            currentResultsPerPage,
            pageSize: size,
        });
        const paginationClassName = classNames(
            'eds-pagination',
            `eds-pagination--${style}`,
        );
        const paginationResultsClassName = classNames(
            'eds-pagination__results',
            {
                'eds-pagination__results--has-results-per-page':
                    onResultsPerPageChange,
                'eds-pagination__results--has-message': showResultsMessage,
            },
        );
        let paginationComponent = null;

        if (showOnSinglePage || pageInfo.pageCount > 1) {
            paginationComponent = (
                <div className={paginationClassName} data-spec="pagination">
                    <div className={paginationResultsClassName}>
                        <ResultsPerPageSelect
                            resultsPerPage={currentResultsPerPage}
                            resultsPerPageOptions={resultsPerPageOptions}
                            onChange={this._handleResultsPerPageChange.bind(
                                this,
                            )}
                            hasOnChange={onResultsPerPageChange}
                        />
                        <ul className="eds-pagination__navigation-group">
                            <PaginationPage
                                isDisabled={pageInfo.isFirstPage}
                                onSelected={this._handleSelected.bind(
                                    this,
                                    currentPage - 1,
                                )}
                                isLoading={isLoading}
                                data-spec="page-prev"
                            >
                                <span className="eds-pagination__navigation-page__arrow eds-l-mar-vert-2 eds-l-mar-hor-2">
                                    <Icon
                                        type={<ChevronLeftChunky />}
                                        title={gettext('Previous Page')}
                                    />
                                </span>
                            </PaginationPage>

                            <PaginationBound
                                show={pageInfo.showPrevEllipsis}
                                onSelected={this._handleSelected.bind(this)}
                            />

                            <Ellipsis show={pageInfo.showPrevEllipsis} />

                            <PaginationPages
                                items={pageInfo.pages}
                                onSelected={this._handleSelected.bind(this)}
                                size={size}
                                pageCount={pageInfo.pageCount}
                            />

                            <Ellipsis show={pageInfo.showNextEllipsis} />

                            <PaginationBound
                                show={pageInfo.showNextEllipsis}
                                bound={pageInfo.pageCount}
                                onSelected={this._handleSelected.bind(this)}
                            />

                            <PaginationPage
                                isDisabled={pageInfo.isLastPage}
                                onSelected={this._handleSelected.bind(
                                    this,
                                    currentPage + 1,
                                )}
                                isLoading={isLoading}
                                data-spec="page-next"
                            >
                                <span className="eds-pagination__navigation-page__arrow eds-l-mar-vert-2 eds-l-mar-hor-2">
                                    <Icon
                                        type={<ChevronRightChunky />}
                                        title={gettext('Next Page')}
                                    />
                                </span>
                            </PaginationPage>
                        </ul>
                        <ResultsMessage
                            showResultsMessage={showResultsMessage}
                            totalNumResults={totalNumResults}
                            currentResultMin={pageInfo.currentResultMin}
                            currentResultMax={pageInfo.currentResultMax}
                        />
                    </div>
                    <PaginationSummary summary={summary} />
                </div>
            );
        }

        return paginationComponent;
    }
}
