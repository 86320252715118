import PropTypes from 'prop-types';
import {
    Recommended,
    Website,
    Ticketing,
    Productivity,
    MarketingPromotion,
    CrmAppMarketplace,
    Email,
    Reporting,
    Popular,
} from '../svgs/index';

import { gettext } from '@eventbrite/i18n';

export const MAX_APPS_TO_DISPLAY = 15;

export const BASE_URL = '/';
export const BASE_APPS_URL = '/apps/';

export const RECOMMENDED = 'recommended';
export const POPULAR = 'popular';

/*
!! If you're a lost soul unsuccessfully trying to update any of the apps listed here,
!! go to https://admin.eventbrite.com/admin/apps/ and update your app info there as well
*/

export const RECOMMENDED_STATIC_APPS = [
    {
        app_url: 'x',
        _type: 'app',
        name: 'Export Attendees',
        banner_url:
            'https://eventbrite-uploader-published-prod.s3.amazonaws.com/a9d8dedab2c396e92808ed85fdf839b4',
        localization: {
            description:
                '<p><br></p><p>Want to add attendee data to email marketing lists, a CRM, or spreadsheet? With app automation tool Zapier, you can <b>automatically get your Eventbrite attendee info</b> into the app where it\'s most valuable to you. That way, you don\'t have to worry about exporting and importing attendee data by hand.</p><p>Zapier allows you to connect Eventbrite data to <b>1,000+ apps</b> you use to run your events like project management, email marketing, CRMs, and spreadsheets. No coding knowledge is needed and automations can be set up by anyone in just a few minutes.</p><p><b>Simple workflows you can create:</b></p><ul><li>Use Eventbrite ticket orders to create webinar registrants</li><li>Add new event registrants to a Google Sheet as they come in</li><li>Search for event attendees in your CRM and add a note or a tag</li><li>Subscribe new event registrants to a list in MailChimp </li><li>Get Slack notifications for high-value orders </li><li>And much more!</li></ul><p><b>Zapier connects to 1,000+ of your favorite tools</b></p><ul><li><p><b>Email Marketing Apps: MailChimp, Constant Contact, ActiveCampaign, AWeber, HubSpot</b></p></li></ul><ul><li><p style=""><b>CRM: Salesforce, Pipedrive, Capsule CRM, Streak</b></p><p style=""><b>Documents: Google Sheets, Airtable, Smartsheet</b></p></li><li><p>Collaboration: Gmail, Slack, Asana, Trello, JIRA</p></li></ul>',
            language: 'en',
            contact_info: {
                support_phone: '',
                sales_phone: '',
                support_email: 'contact@zapier.com',
                sales_email: '',
            },
            get_started_url: 'https://zapier.com/apps/eventbrite/',
            resource_links: [
                {
                    url: 'https://zapier.com/apps/eventbrite/integrations',
                    id: '5709',
                    title: '1,000+ Ways to Automate Your Event Management',
                },
                {
                    url: 'https://zapier.com/',
                    id: '5710',
                    title: 'Website',
                },
                {
                    url: 'https://zapier.com/apps/eventbrite/integrations',
                    id: '5711',
                    title: 'Eventbrite Integrations',
                },
                {
                    url: 'https://www.youtube.com/watch?v=v98d2tH3wfc&list=PLqzJRGwum4KMoJdVeW2z_qFLGe3Wl6Xo4&index=1',
                    id: '5712',
                    title: 'Video: Zapier 101',
                },
            ],
            short_description: 'Easy automation for busy people',
            screenshots: [
                {
                    sort_order: '65535',
                    image_url:
                        'https://eventbrite-uploader-published-prod.s3.amazonaws.com/d89fdd73b0b8d428c73fd36098c8a76f',
                    id: '7706',
                },
                {
                    sort_order: '65535',
                    image_url:
                        'https://eventbrite-uploader-published-prod.s3.amazonaws.com/b7118eee09d39fa812e56875d8250511',
                    id: '7707',
                },
            ],
            id: '1820',
            support_description:
                '<p>Visit https://zapier.com/help/eventbrite/ for help connecting Eventbrite with Zapier or get in touch with Zapier support via email: contact@zapier.com</p>',
        },
        service_categories: [
            {
                slug: 'syncs-attendees',
                name_localized: 'Syncs Attendees',
                id: '1',
                name: 'Syncs Attendees',
            },
        ],
        slug: 'zapier',
        event_categories: [
            {
                slug: 'conferences',
                name_localized: 'Conferences',
                id: '1',
                name: 'Conferences',
            },
        ],
        details: {
            _type: 'app_details',
            is_installed: false,
        },
        logo_url:
            'https://eventbrite-uploader-published-prod.s3.amazonaws.com/661db384be7e5ce8188006e2255b17c4',
        id: '96888',
        categories: [
            {
                slug: 'productivity',
                name_localized: 'Productivity',
                id: '6',
                name: 'Productivity',
            },
        ],
    },
    {
        app_url: 'x',
        _type: 'app',
        name: 'HubSpot ',
        banner_url:
            'https://eventbrite-uploader-published-prod.s3.amazonaws.com/310a09d62a53c3c395bffde3269317d3',
        localization: {
            description:
                '<p>Sync event data to HubSpot, and action based on registration status. See the full picture by combining online and offline events. The integration automatically turns event registrants in Eventbrite into contacts in HubSpot, while recording event data on the HubSpot contact timeline. With both tools working together, you can easily action event data, to move event attendees to prospects to paying customers.</p><p><b>Popular use-cases:</b></p><ul><li><span style="font-family: inherit;">Create lists and workflows in HubSpot based on event registration status to re-engage no shows, nurture registrants, and close attendees.</span></li><li>Understand how registrants have engaged with your company before they show up to the event.</li><li>Report on the ROI after your event is over.</li></ul><p>The integration is free for all levels of HubSpot and Eventbrite users.<br></p>',
            language: 'en',
            contact_info: {
                support_phone: '1-888-482-7768',
                sales_phone: '1-888-482-7768',
                support_email: 'noreply@hubspot.com',
                sales_email: '',
            },
            get_started_url: 'https://www.hubspot.com/integrations/eventbrite',
            resource_links: [
                {
                    url: 'http://www.hubspot.com/integrations/eventbrite',
                    id: '5226',
                    title: 'Integration Overview',
                },
                {
                    url: 'https://knowledge.hubspot.com/articles/kcs_article/integrations/how-to-connect-hubspot-and-eventbrite',
                    id: '5245',
                    title: 'How to connect HubSpot and Eventbrite',
                },
                {
                    url: 'https://knowledge.hubspot.com/articles/kcs_article/integrations/how-can-i-use-the-eventbrite-integration-with-hubspot',
                    id: '5246',
                    title: 'How can I use the Eventbrite integration with HubSpot?',
                },
                {
                    url: 'https://help.hubspot.com/',
                    id: '5247',
                    title: 'Support',
                },
            ],
            short_description: 'Sync and track leads from your events',
            screenshots: [
                {
                    sort_order: '65535',
                    image_url:
                        'https://eventbrite-uploader-published-prod.s3.amazonaws.com/3b8a23e5ab9ceb2c512b1cd538513859',
                    id: '7039',
                },
                {
                    sort_order: '65535',
                    image_url:
                        'https://eventbrite-uploader-published-prod.s3.amazonaws.com/a204020b31d688cbf99560e4b36bcf25',
                    id: '7040',
                },
                {
                    sort_order: '65535',
                    image_url:
                        'https://eventbrite-uploader-published-prod.s3.amazonaws.com/8cbf7094e0b7a12116772e6b4bc7568f',
                    id: '7041',
                },
                {
                    sort_order: '65535',
                    image_url:
                        'https://eventbrite-uploader-published-prod.s3.amazonaws.com/31e36348d06d8cba61b5791d550d1711',
                    id: '7060',
                },
            ],
            id: '1505',
            support_description: '<p>https://help.hubspot.com/</p>',
        },
        service_categories: [
            {
                slug: 'syncs-attendees',
                name_localized: 'Syncs Attendees',
                id: '1',
                name: 'Syncs Attendees',
            },
        ],
        slug: 'hubspot',
        event_categories: [
            {
                slug: 'conferences',
                name_localized: 'Conferences',
                id: '1',
                name: 'Conferences',
            },
        ],
        details: {
            _type: 'app_details',
            is_installed: false,
        },
        logo_url:
            'https://eventbrite-uploader-published-prod.s3.amazonaws.com/bafd35d152aa589259888c86d2b4adde',
        id: '43315',
        categories: [
            {
                slug: 'email',
                name_localized: 'Email',
                id: '7',
                name: 'Email',
            },
        ],
        flatCategories: ['Email'],
    },
    {
        app_url: 'x',
        _type: 'app',
        name: 'Constant Contact',
        banner_url:
            'https://eventbrite-uploader-published-prod.s3.amazonaws.com/6cc15207ef444c6aa32b129c6ac93303',
        localization: {
            description:
                '<p>Constant Contact’s integration with Eventbrite makes it easier than ever to manage and promote events. Easily add events to any email template, track registrations and send follow up emails, and import contacts.\n</p><p><b>With Constant Contact you can: </b></p><ul><li>Easily create compelling emails that drive more event registrations by adding published Eventbrite events that automatically links to your event page. </li><li>See who has responded to your event invitation and who hasn’t, as well as who has opened and engaged with your email - so that you can send out follow up emails to specific audiences.</li><li>Sync registrants of an event to easily send out reminders and follow-up emails to your registrants. </li></ul><p>Unleash the power of your small business or nonprofit —and drive more results—by integrating Constant Contact with Eventbrite. </p>',
            language: 'en',
            contact_info: {
                support_phone: '866-289-2101',
                sales_phone: '855-797-4708',
                support_email: 'eventbrite@constantcontact.com',
                sales_email: '',
            },
            get_started_url:
                'https://www.constantcontact.com/features/apps-integration/eventbrite?pn=eventbrite&cc=SpectrumListing',
            resource_links: [
                {
                    url: 'https://knowledgebase.constantcontact.com/articles/KnowledgeBase/24699-Import-Eventbrite-Contacts-into-Constant-Contact?pn=eventbrite&cc=SpectrumListing_Contacts',
                    id: '5626',
                    title: 'Import Eventbrite Contacts to Constant Contact',
                },
                {
                    url: 'https://knowledgebase.constantcontact.com/articles/KnowledgeBase/24407-Tutorial-Add-an-Eventbrite-Block-to-Your-Email?pn=eventbrite&cc=SpectrumListing_EventBlock',
                    id: '5627',
                    title: 'Add Eventbrite events to an email',
                },
                {
                    url: 'https://knowledgebase.constantcontact.com/articles/KnowledgeBase/24700-Eventbrite-Responses-Reporting?pn=eventbrite&cc=SpectrumListing_Registrants',
                    id: '5628',
                    title: 'Track registrations for Eventbrite event from a Constant Contact email',
                },
            ],
            short_description: 'Sync subscribers from your events',
            screenshots: [
                {
                    sort_order: '65535',
                    image_url:
                        'https://eventbrite-uploader-published-prod.s3.amazonaws.com/c2adbf7437a46e0bbad4b53de8949018',
                    id: '7600',
                },
                {
                    sort_order: '65535',
                    image_url:
                        'https://eventbrite-uploader-published-prod.s3.amazonaws.com/f213a4aa0f64db248bd7e0c50a1aee85',
                    id: '7601',
                },
                {
                    sort_order: '65535',
                    image_url:
                        'https://eventbrite-uploader-published-prod.s3.amazonaws.com/1735fa4fcb01371d12aba337ab6519bd',
                    id: '7602',
                },
                {
                    sort_order: '65535',
                    image_url:
                        'https://eventbrite-uploader-published-prod.s3.amazonaws.com/e5239b6c34ad6da49ad575222606d942',
                    id: '7603',
                },
            ],
            id: '1764',
            support_description:
                "<p><b>Import Eventbrite Contacts into Constant Contact </b></p><p><b></b>To get started, you just need to log into your Eventbrite account: \n</p><ol><li>Click Integrations. </li><li>On the Eventbrite block, click the three dots to open the menu and click \"Connect account\". </li><li>Log into your Eventbrite account. </li><li>Click Allow. </li><li>Click Import Contacts. </li><li>Select your lists from Eventbrite. </li><li>Confirm that you have permission to email your contacts. (<b>Note:</b> Eventbrite has a confirmed opt-in question you can add to your event registration process to ensure you're emailing only those who want to receive your emails.) </li><li>Click Import now. </li></ol><p></p><p><b>Add Eventbrite events to an email (available in Email Plus)</b></p><p>To get started, you just need to log into your Eventbrite account: </p><ol><li>Drag the Eventbrite block into your template. Use the Guidelines to help you find the right spot. The block can stretch across the width of your template or be added to a column. </li><li>Click on the Eventbrite block and click Edit. (<b>Note:</b> If you've already synced your Eventbrite account, skip to last step.) </li><li>Click Connect. </li><li>Log into your Eventbrite account. Once you've synced your account, you won't have to go through the steps to login every time you add the block to an email. </li><li>Click Allow. </li><li>Click on the Eventbrite block and click Edit. </li><li>Select the event you want to display in your email and click Insert.  </li></ol>",
        },
        service_categories: [
            {
                slug: 'syncs-attendees',
                name_localized: 'Syncs Attendees',
                id: '1',
                name: 'Syncs Attendees',
            },
        ],
        slug: 'constant-contact',
        event_categories: [
            {
                slug: 'non-profits-and-fundraisers',
                name_localized: 'Non-profits and Fundraisers',
                id: '4',
                name: 'Non-profits and Fundraisers',
            },
        ],
        details: { _type: 'app_details', is_installed: false },
        logo_url:
            'https://eventbrite-uploader-published-prod.s3.amazonaws.com/2df2627d1d825ea789ba1eefee4b13d8',
        id: '75424',
        categories: [
            { slug: 'email', name_localized: 'Email', id: '7', name: 'Email' },
        ],
        flatCategories: ['Email'],
    },
    {
        app_url: 'x',
        _type: 'app',
        name: 'Custom Badges',
        banner_url:
            'https://eventbrite-uploader-published-prod.s3.amazonaws.com/cc9078ec6592adbf598318c84db631e9.png',
        localization: {
            description:
                '<p></p><p>A good first impression is important for forming business relationships and it can never be recreated - can you imagine the pressure on conference attendees who are making tens or hundreds at each event?</p><p>As a conference organizer, you can do your part by making beautiful name badges - and ConferenceBadge.com makes this incredibly simple!</p><p>Connect your Eventbrite account, so you can create you name badge template and automatically import your attendees for easy name badge creation.</p>',
            language: 'en',
            contact_info: {
                support_phone: '',
                sales_phone: '',
                support_email: 'info@conferencebadge.com',
                sales_email: '',
            },
            get_started_url: 'http://www.conferencebadge.com/',
            resource_links: [
                {
                    url: 'http://blog.eventbrite.com/beautiful-and-simple-name-badges/',
                    id: '1771',
                    title: 'Eventbrite Blog Post',
                },
                {
                    url: 'http://www.conferencebadge.com/#pricing',
                    id: '1772',
                    title: 'Pricing',
                },
            ],
            short_description: 'Create beautiful, custom name badges',
            screenshots: [
                {
                    sort_order: '65535',
                    image_url:
                        'https://eventbrite-uploader-published-prod.s3.amazonaws.com/1d1ebe71bf6904fb446af788c3afe9b6',
                    id: '2191',
                },
                {
                    sort_order: '65535',
                    image_url:
                        'https://eventbrite-uploader-published-prod.s3.amazonaws.com/a61b503c02e9e4c915e8207130656eb0',
                    id: '2192',
                },
                {
                    sort_order: '65535',
                    image_url:
                        'https://eventbrite-uploader-published-prod.s3.amazonaws.com/ea8ed5b5e998935cb1ab1275eb0d1a14',
                    id: '2193',
                },
                {
                    sort_order: '65535',
                    image_url:
                        'https://eventbrite-uploader-published-prod.s3.amazonaws.com/ccee94905f90d68844a42a9231a8e848',
                    id: '2194',
                },
            ],
            id: '314',
            support_description:
                '<p>Looking to order in large volumes? Contact us for pricing! </p>',
        },
        service_categories: [
            {
                slug: 'syncs-attendees',
                name_localized: 'Syncs Attendees',
                id: '1',
                name: 'Syncs Attendees',
            },
        ],
        slug: 'conference-badges',
        event_categories: [
            {
                slug: 'conferences',
                name_localized: 'Conferences',
                id: '1',
                name: 'Conferences',
            },
        ],
        details: { _type: 'app_details', is_installed: false },
        logo_url:
            'https://eventbrite-uploader-published-prod.s3.amazonaws.com/e01f7275f74ef87df387dedfb04adf6c',
        id: '22289',
        categories: [
            {
                slug: 'tickets-and-onsite',
                name_localized: 'Tickets & Onsite',
                id: '3',
                name: 'Tickets & Onsite',
            },
            {
                slug: 'productivity',
                name_localized: 'Productivity',
                id: '6',
                name: 'Productivity',
            },
        ],
        flatCategories: ['Tickets & Onsite', 'Productivity'],
    },
    {
        app_url: 'x',
        _type: 'app',
        name: 'Events Calendar',
        banner_url:
            'https://eventbrite-uploader-published-prod.s3.amazonaws.com/446db232bd2c193addc675ecdd6f8165',
        localization: {
            description:
                '<p>Add a beautiful events calendar to your website and inform your audience about upcoming events.</p><ul><li>Sync your calendar with Eventbrite, Google Calendar or Outlook Calendar</li><li>Choose from 3 calendar layouts - display events in monthly, weekly or agenda view.</li><li>Customize the calendar colors to best match your site’s look and feel.</li><li>Mobile browsers support with custom designed layouts.</li><li>25 languages supported, including English, Spanish, French, German, Portuguese, Russian, Czech, Danish, Greek, Hebrew, Italian, Japanese, Norwegian and Polish</li></ul><p></p>',
            language: 'en',
            contact_info: {
                support_phone: '',
                sales_phone: '',
                support_email: 'calendar.eventbrite@inffuseapps.zendesk.com',
                sales_email: '',
            },
            get_started_url: 'https://eventscalendar.co/',
            resource_links: [
                {
                    url: 'http://links.inlightlabs.com/weebly-calendar',
                    id: '5632',
                    title: 'Add to Weebly',
                },
                {
                    url: 'http://links.inlightlabs.com/shopify-calendar',
                    id: '5633',
                    title: 'Add to Shopify',
                },
                {
                    url: 'http://links.inlightlabs.com/wix-calendar',
                    id: '5634',
                    title: 'Add to Wix',
                },
            ],
            short_description: 'Add an events calendar to your website',
            screenshots: [
                {
                    sort_order: '65535',
                    image_url:
                        'https://eventbrite-uploader-published-prod.s3.amazonaws.com/4b88aa6709b12c584fbeaa882a6f9946',
                    id: '7612',
                },
                {
                    sort_order: '65535',
                    image_url:
                        'https://eventbrite-uploader-published-prod.s3.amazonaws.com/dc2e5dfe6b30db6fc8ead0ab19b6d8c3',
                    id: '7613',
                },
                {
                    sort_order: '65535',
                    image_url:
                        'https://eventbrite-uploader-published-prod.s3.amazonaws.com/11b5027fc43e5e98b5968a89bb8a74a9',
                    id: '7614',
                },
                {
                    sort_order: '65535',
                    image_url:
                        'https://eventbrite-uploader-published-prod.s3.amazonaws.com/41df50572f6e33fd49cdf2e805c4ecef',
                    id: '7615',
                },
            ],
            id: '1774',
            support_description:
                '<p>For any question or feedback please reach out to calendar.eventbrite@inffuseapps.zendesk.com </p>',
        },
        service_categories: [
            {
                slug: 'syncs-events',
                name_localized: 'Syncs Events',
                id: '3',
                name: 'Syncs Events',
            },
        ],
        slug: 'events-calendar',
        event_categories: [
            {
                slug: 'conferences',
                name_localized: 'Conferences',
                id: '1',
                name: 'Conferences',
            },
        ],
        details: { _type: 'app_details', is_installed: false },
        logo_url:
            'https://eventbrite-uploader-published-prod.s3.amazonaws.com/7bc18900e92d4f5928322695353181e8',
        id: '69846',
        categories: [
            {
                slug: 'website',
                name_localized: 'Website',
                id: '5',
                name: 'Website',
            },
        ],
        flatCategories: ['Website'],
    },
];

export const CATEGORIES_INFO_MAP = {
    crm: {
        name: gettext('CRM'),
        illustration: CrmAppMarketplace,
        description: gettext('Apps to generate and manage new leads'),
        slug: 'crm',
    },
    email: {
        name: gettext('Email'),
        illustration: Email,
        description: gettext('Apps to elevate your email marketing'),
        slug: 'email',
    },
    reporting: {
        name: gettext('Reporting'),
        illustration: Reporting,
        description: gettext('Apps that do math'),
        slug: 'reporting',
    },
    'marketing-and-promotion': {
        name: gettext('Marketing & Promotion'),
        illustration: MarketingPromotion,
        description: gettext('Apps to reach more people in more places'),
        slug: 'marketing-and-promotion',
    },
    productivity: {
        name: gettext('Productivity'),
        illustration: Productivity,
        description: gettext('Apps to streamline, automate, and get organized'),
        slug: 'productivity',
    },
    'tickets-and-onsite': {
        name: gettext('Tickets & Onsite'),
        illustration: Ticketing,
        description: gettext(
            'Apps to print tickets & badges and make your day-of shine',
        ),
        slug: 'tickets-and-onsite',
    },
    website: {
        name: gettext('Website'),
        illustration: Website,
        description: gettext('Apps to create amazing web experiences'),
        slug: 'website',
    },
    popular: {
        name: gettext('Popular'),
        illustration: Popular,
        description: gettext('Top apps for event creators'),
    },
    recommended: {
        name: gettext('Recommended'),
        illustration: Recommended,
        //eslint-disable-next-line quotes
        description: gettext("Apps we know you'll love"),
    },
};

export const CURRENT_CATEGORIES_TO_NEW_MAP = {
    crm: 'crm',
    'email-marketing': 'email',
    'promotion-and-advertising': 'marketing-and-promotion',
    'marketing-tools': 'marketing-and-promotion',
    websites: 'website',
    'mobile-apps': 'tickets-and-onsite',
    'multi-session-management': 'productivity',
    'onsite-opertaions': 'tickets-and-onsite',
    other: 'productivity',
    fundraising: 'marketing-and-promotion',
    'finance-and-reporting': 'reporting',
    'printed-tickets': 'tickets-and-onsite',
    'app-connectors': 'productivity',
    'event-management-software': 'productivity',
    'eventbrite-dashboard': 'productivity',
};

export const CURRENT_APPS_TO_NEW_MAP = {
    toneden: 'paid-social-ads',
};

export const APP_DETAILS_SHAPE = PropTypes.shape({
    isInstalled: PropTypes.bool,
    _type: PropTypes.string,
});

export const APP_EXTENSION_SHAPE = PropTypes.shape({
    clientId: PropTypes.string,
    extensionUrl: PropTypes.string,
    requiresOauth: PropTypes.bool,
    slug: PropTypes.string,
});

export const RESOURCE_LINKS_SHAPE = PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
    }),
);

export const SCREENSHOTS_SHAPE = PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.string,
        imageUrl: PropTypes.string,
        sortOrder: PropTypes.string,
    }),
);

export const APP_LOCALIZATION_SHAPE = PropTypes.shape({
    contactInfo: PropTypes.shape({
        supportEmail: PropTypes.string,
    }),
    description: PropTypes.string,
    getStartedUrl: PropTypes.string,
    id: PropTypes.string,
    language: PropTypes.string,
    resourceLinks: RESOURCE_LINKS_SHAPE,
    screenshots: SCREENSHOTS_SHAPE,
    shortDescription: PropTypes.string,
    supportDescription: PropTypes.string,
});

export const RATINGS_SUMMARY_SHAPE = PropTypes.shape({
    totalReviews: PropTypes.number,
    rating: PropTypes.number,
    stars: PropTypes.shape({
        one: PropTypes.number,
        two: PropTypes.number,
        three: PropTypes.number,
        four: PropTypes.number,
        five: PropTypes.number,
    }),
});

export const RATINGS_SHAPE = PropTypes.shape({
    rating: PropTypes.number,
    created: PropTypes.string,
    locale: PropTypes.string,
    reviewText: PropTypes.string,
    reviewTitle: PropTypes.string,
    id: PropTypes.string,
});

export const RATINGS_SUMMARY_STARS_SHAPE = PropTypes.shape({
    one: PropTypes.number,
    two: PropTypes.number,
    three: PropTypes.number,
    four: PropTypes.number,
    five: PropTypes.number,
});

export const REVIEW_REPLY_SHAPE = PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    created: PropTypes.string,
});

export const DEFAULT_LANGUAGE_TO_LOCALE = {
    'de-de': 'de_DE',
    'en-au': 'en_AU',
    'en-ca': 'en_CA',
    'en-gb': 'en_GB',
    'en-us': 'en_US',
    'es-es': 'es_ES',
    'fr-ca': 'fr_CA',
    'fr-fr': 'fr_FR',
    'it-it': 'it_IT',
    'nl-nl': 'nl_NL',
    'pt-pt': 'pt_PT',
    'pt-br': 'pt_BR',
    'es-ar': 'es_AR',
    'en-se': 'en_SE',
};

export const REVIEW_SUBMIT_ERRORS = {
    APP_ALREADY_RATED: 'APP_ALREADY_RATED',
    APP_NOT_INSTALLED: 'APP_NOT_INSTALLED',
    UNKNOWN: 'UNKNOWN',
    ARGUMENTS_ERROR: 'ARGUMENTS_ERROR',
};

export const REVIEW_SUBMIT_ERROR_PROP_TYPE = [
    REVIEW_SUBMIT_ERRORS.APP_ALREADY_RATED,
    REVIEW_SUBMIT_ERRORS.APP_NOT_INSTALLED,
    REVIEW_SUBMIT_ERRORS.UNKNOWN,
];

export const HALF_STAR_SIZE_PROP_TYPE = [
    'xsmall',
    'small',
    'medium',
    'large',
    'xlarge',
];

export const MINIMUM_NUMBER_OF_APP_RATINGS = 5;

export const APP_RATINGS_PER_PAGE = 3;

export const APP_RATING_SORT_OPTIONS = {
    MOST_RECENT: 'created_desc',
    TOP_RATED: 'rating_desc',
};

export const WRITE_REVIEW_FORM_NAME = 'writeReview';

export const SMALL_WIDE = 660;
export const LARGE_WIDE = 1272;

export const LARGE = 'LARGE';
export const MEDIUM = 'MEDIUM';
export const SMALL = 'SMALL';
